import {useAsyncCurrentUserUsername} from "~/composables/useDatabaseValues.js";

export default defineNuxtRouteMiddleware(async _ => {
    const {data: username} = await useAsyncCurrentUserUsername();

    if (!username.value) {
        throw createError({
            statusCode: 403,
            statusMessage: 'Forbidden',
        });
    }
    const userLower = username.value.toLowerCase();
    const adminUsernames = ['theroccob', 'tiff'];
    if (!adminUsernames.includes(userLower)) {
        throw createError({
            statusCode: 403,
            statusMessage: 'Forbidden',
        })
    }
})